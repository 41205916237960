import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Img from 'gatsby-image';
import { Box, Heading, Text } from 'grommet';
import SectionTitle from 'components/title/sectionTitle';
import { CoverSlider, ClientSlider } from 'components/slider';
import RouteButton from 'components/routeButton';





const Index = ({ data }) => (
  <Layout>
    <Box fill="horizontal">
      
        <SectionTitle title="Clients" />

        <ClientSlider clients={data.homeJson.clients} />

        
     
    </Box>
  </Layout>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query clientPageQuery {
    homeJson {
      products {
        title
        text
        pic {
          childImageSharp {
            fluid(maxWidth: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        link
      }
      coverImages {
        image {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        text
        subtext
        link
        background
      }
      clients {
        image {
          childImageSharp {
            fluid(maxWidth: 200, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;